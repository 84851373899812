import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  orderBy,
  onSnapshot,
  doc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  setDoc, 
  addDoc, 
  deleteDoc,
  arrayUnion, 
  arrayRemove 
} from 'firebase/firestore'
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL, 
  deleteObject } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyB_UofZQCpDTxB9xm277pb9OQbatLSYTz4",
  authDomain: "bibiscloud-d8c0a.firebaseapp.com",
  projectId: "bibiscloud-d8c0a",
  storageBucket: "bibiscloud-d8c0a.appspot.com",
  messagingSenderId: "887204845232",
  appId: "1:887204845232:web:886f6a6d8ecee69dfd0d75"
};

const app = initializeApp(firebaseConfig)

export const fire = {
  collection, 
  query, 
  orderBy,
  where, 
  doc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  setDoc, 
  addDoc, 
  deleteDoc,
  getStorage,
  ref,
  onSnapshot,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  arrayUnion,
  arrayRemove
};
export const auth = getAuth(app); 
export const db = getFirestore(app);
export const store = getStorage(app); 