import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ReactCommonmark from 'react-commonmark'
import { useBlog } from './context/BlogContext'
import { useClip } from './hooks/useClip'

const Post = () => {
    const {slug} = useParams()
    const navigate = useNavigate()
    const {submitPost, loggedIn, usePost} = useBlog()
    const copyUrl = useClip()
    const [post] = usePost(slug)
    
    const handleEdit = (e) => {
        navigate(`/edit-post/${e}`)
    }

    async function handleShare(props) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(props);
        } else {
          return document.execCommand('copy', true, props);
        }
      }

    return(
        <>
            {post ? 
                <article id='post'>
                <span>
                    <hgroup>
                        <h1>{post?.title}</h1>
                        <h3>{post?.date}</h3>
                    </hgroup>
                    <section id='post-hero'>{post?.hero ? <img src={post?.hero} id='hero' /> : ''}</section>
                    <section>
                        <ReactCommonmark source={post?.post || ''} />
                        <footer>
                            {loggedIn ? 
                                <button onClick={()=>handleEdit(post?.id)}>edit</button> : 
                                <button onClick={()=>handleShare(window.location.href)}>share</button>
                            }
                        </footer>
                    </section>    
                    </span>    
                </article> : 'Loading Post...'
            }
        </>
         
    )
}
export default Post