import {useState, useEffect, useRef} from 'react'
import { useBlog } from "./context/BlogContext"
import { Editor } from '@tinymce/tinymce-react'

const Settings = () => {
    const {settings, updateSettings, theme, uploadHeroImg, deleteHeroImg} = useBlog()
    const [data, setData] = useState('')
    const editorRef = useRef(null)

    useEffect(()=>{
        try{
            let [general] = settings ? settings?.filter(f=>f?.id === 'general') : ''
            setData(general)
        } catch {
            console.log('Error fetching settings')
        }
    },[])

    const handleChangeTheme = (e) => {
        updateSettings({key: 'currentTheme', value: e?.target?.value})
    }

    const handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target
        setData({...data, [name]: value})
    }

    const handleBlur = (e) => {
        updateSettings({key: e?.target?.name, value: e?.target?.value})
    }

    const handleSubmit = () => {
        updateSettings({key: 'aboutDesc', value: editorRef.current.getContent()})
    }

    const handleFileChange = async(e) => {
        const img = e.target.files[0]
        const urlRef = await uploadHeroImg({id: 'aboutImg', image: img})
        updateSettings({key: 'aboutImg', value: urlRef})
    }

    const handleDeleteImg = async(e) => {
        await deleteHeroImg({id: 'aboutImg', image: data?.aboutImg})
        updateSettings({key: 'aboutImg', value: ''})
    }

    return(
        <article>
            <section>

                <h3>Blog Settings</h3>

                <label htmlFor='theme'> Theme
                    <select name='theme' id='theme' value={theme} onChange={handleChangeTheme} >
                        <option value='pink'>Pink</option>
                        <option value='purple'>Purple</option>
                        <option value='orange'>Orange</option>
                        <option value='blue'>Blue</option>
                        <option value='green'>Green</option>
                        <option value='cloud'>Cloud</option>
                    </select>
                </label>

                <label htmlFor='subtitle'>Title
                    <input name='title' id='title' value={data?.title} onChange={handleChange} onBlur={handleBlur} />    
                </label>

                <label htmlFor='subtitle'>Subtitle
                    <input name='subtitle' id='subtitle' value={data?.subtitle} onChange={handleChange} onBlur={handleBlur} />    
                </label>

            </section>
            <section>

                <h3>About Page Settings</h3>

                <label htmlFor='aboutTitle'> Title
                    <input name='aboutTitle' id='aboutTitle' value={data?.aboutTitle} onChange={handleChange} onBlur={handleBlur} />    
                </label>

                {!data?.aboutImg ?
                    <label htmlFor='aboutImg'> Image
                        <input name='aboutImg' id='aboutImg' type='file' onChange={handleFileChange} />    
                    </label> : 
                    <img src={data?.aboutImg} id='hero' onClick={handleDeleteImg} />
                }

                <Editor
                    apiKey='ztjy6vniszcwdo9afxwqeerknzgoiqruwto8buj0kzhk3nij'
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={data?.aboutDesc || 'About Me...'}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />   

            </section>

            <footer>
                <button onClick={()=>handleSubmit()}>Save</button>
            </footer>
        </article>
    )
}
export default Settings